import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDebounced } from 'hooks'

import { Button, ColorPicker, Flex, Grid, Icon, IconButton, Link, Text, MessageBox } from 'components'

import { resetAppTheme, setAppTheme } from 'reduxStore/reducers/apptheme/appThemeSlice'
import regexDictionary from 'scripts/regexDictionary'
import './company_theme.scss'
import FakeComboBox from './stateless/FakeCombobox/FakeComboBox'

const CompanyTheme = () => {
    /*---------------------------------------STATE-----------------------------------------*/
    const { appTheme } = useSelector(store => store)
    const { mainColor, mainColorHover, secondaryColorHover, defaultColors } = appTheme

    const [colors, setColors] = useState()
    const [touched, setTouched] = useState(false)
    const [ defaultValues, setDefaultValues ] = useState(true)

    /*---------------------------------------REFS-----------------------------------------*/
    const previewContainerRef = useRef()
    const mainColorRef = useRef()
    const mainColorHoverRef = useRef()
    const secondaryColorHoverRef = useRef()
    const messageBoxRef = useRef()

    /*---------------------------------------HOOKS-----------------------------------------*/
    const dispatch = useDispatch()

    useEffect(() => {
        setColors({ mainColor, mainColorHover, secondaryColorHover})
    }, [mainColor, mainColorHover, secondaryColorHover])

    useEffect(( ) => {
        const savedColors = { mainColor, mainColorHover, secondaryColorHover }
        const colorsKeys = Object.keys(defaultColors)

        const areDefaultColors = colorsKeys.every((key) => savedColors[key] === defaultColors[key])
        let areChangedColorsDefault = true

        if(colors)
            areChangedColorsDefault = colorsKeys.every((key) => colors[key] === defaultColors[key])

        setDefaultValues(areDefaultColors && areChangedColorsDefault)
    }, [ mainColor, mainColorHover, secondaryColorHover, defaultColors, colors])


    /*---------------------------------------FUNCTIONS-----------------------------------------*/
    const debouncedGenerateTheme = useDebounced((mainColor) => {
        const container = previewContainerRef.current
        if (container && mainColor.match(regexDictionary.hexColor))
            container.style.setProperty('--filter--active', global.generateFilter(mainColor))
    }, 300)

    const changeThemeColors = (color, value) => {
        const container = previewContainerRef.current
        if (color === 'mainColor') {
            debouncedGenerateTheme(value)
            container.style.setProperty('--component--color', value)
        }
        if (color === 'mainColorHover')
            container.style.setProperty('--component--color--active', value)
        if (color === 'secondaryColorHover')
            container.style.setProperty('--component--option--bg-color--hover', value)
    }

    const handleChangeColorPicker = (color, value) => {
        setTouched(true)
        setColors( colors => ({...colors, [color]: value}))
        changeThemeColors(color, value)
    }

    const applyTheme = () => {
        let valid = false
        if(secondaryColorHoverRef.current.validate() && mainColorHoverRef.current.validate() && mainColorRef.current.validate() && mainColor !== '' && mainColorHover !== '' && secondaryColorHover !== ''){
            valid = true            
        }
        if(valid){
            dispatch(setAppTheme({...colors })).then(() => {
                setTouched(false)
            })
        }else{
            messageBoxRef.current.show({
                content: <Text medium align="center">Hay campos vacios o colores invalidos</Text>,
                buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
            })
        }
    }

    const cancelTheme = () => {
        setTouched(false)
        setColors({ mainColor, mainColorHover, secondaryColorHover})
        changeThemeColors("mainColor", mainColor)
        changeThemeColors("mainColorHover", mainColorHover)
        changeThemeColors("secondaryColorHover", secondaryColorHover)
    }

    const resetTheme = () => {
        dispatch(resetAppTheme()).then(() => {
            setTouched(false)
            Object.keys(defaultColors).forEach((key) => {
                changeThemeColors(key, defaultColors[key])
            })
            setColors({ ...defaultColors })
        })
    }


    /*---------------------------------------RENDER-----------------------------------------*/

    return (
        <Grid className='company_theme_container' gap='1.8rem'>
            <MessageBox ref={messageBoxRef} />
            <Text bold align='center'>Tema</Text>
            <Grid columns='auto 1fr' gap="2.857rem" selfX="center">
                <Grid className='company_theme__form_container' gap='1.73rem' contentY='start' contentX='center'>
                    <Grid gap='0.8rem'>
                        <Text regular>Color Principal</Text>
                        <ColorPicker colorRef={mainColorRef} value={colors?.mainColor} onChange={v => handleChangeColorPicker('mainColor', v)} />
                    </Grid>
                    <Grid gap='0.8rem'>
                        <Flex direction='column'>
                            <Text regular>Hover</Text>
                            <Text regular color="second" size='2'>(Ratón encima)</Text>
                        </Flex>
                        <ColorPicker colorRef={mainColorHoverRef} value={colors?.mainColorHover} onChange={v => handleChangeColorPicker('mainColorHover', v)} />
                    </Grid>
                    <Grid gap='0.8rem'>
                        <Flex direction='column'>
                            <Text regular>Hover Secundario</Text>
                            <Text regular color="second" size='2'>(Ratón encima secundario)</Text>
                        </Flex>

                        <ColorPicker colorRef={secondaryColorHoverRef} value={colors?.secondaryColorHover} onChange={v => handleChangeColorPicker('secondaryColorHover', v)} />
                    </Grid>
                </Grid>
                <Grid
                    ref={previewContainerRef}
                    className={`company_theme__preview_container${touched ? '' : ' hide_content'}`}
                    gap='1.15rem'
                    contentY='start'
                    itemsY='center'>
                    <Text bold align='center'>Vista previa</Text>
                    <Flex align='center'>
                        <Text regular size="2">Esto es un texto</Text>
                        <IconButton style={{margin: '0 0.57rem', cursor: 'initial', padding: '3px'}} padding="3px" size="0.85" filter='active' icon='help' />
                        <IconButton style={{ backgroundColor: 'var(--component--option--bg-color--hover)', cursor: 'initial', padding: '3px' }} filter='active' icon='icono-agregar' />
                        <IconButton style={{ backgroundColor: 'var(--component--option--bg-color--hover)', margin: '0 0.28rem', cursor: 'initial', padding: '3px' }} padding="3px" filter='active' icon='download' />
                        <IconButton style={{ backgroundColor: 'var(--component--option--bg-color--hover)', cursor: 'initial', padding: '3px' }} filter='active' icon='Gear' />
                    </Flex>
                    <Grid gap="1.4285rem">
                        <Link style={{cursor: 'default'}} underline bold size="2">Esto es un enlace</Link>
                        <FakeComboBox />
                        <Button style={{cursor: 'default'}} className='fake_button'>Botón</Button>
                        <Button style={{cursor: 'default'}} className='fake_button hovered'>Botón con ratón encima</Button>
                    </Grid> 
                </Grid>
            </Grid>
            {touched && 
                <Grid columns='1fr 1fr' gap="1rem" selfX="center">
                    <Button variant="outlined" onClick={cancelTheme}>Cancelar</Button>
                    <Button onClick={applyTheme}>Aplicar tema</Button> 
                </Grid>
            }
            {!defaultValues &&
                <Flex className='company_theme__rever_theme_btn' align='center' onClick={resetTheme}>
                    <Text regular color="second">Regresar colores originales</Text>
                    <Icon icon='refresh' filter='active' />
                </Flex>
            }
        </Grid >
    )
}

export default CompanyTheme