import React, { useCallback, useRef } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimerContext } from 'react-idle-timer'

import { logout } from 'reduxStore/reducers/auth/authSlice'

import { CharacterField, Grid, Icon, Img, Link, Text } from 'components'
import { setAppOnIdle } from 'reduxStore/reducers/app/appSlice'

import regexDictionary from 'scripts/regexDictionary'

import './idle_app_mask.scss'
import { useState } from 'react'

const pinLength = 4

const IdleAppMask = () => {
    /*---------------------------------------STATE-----------------------------------------*/
    const { isIdle } = useSelector(store => store.app)
    const [wrongPin, setWrongPin] = useState('')

    /*---------------------------------------REF-----------------------------------------*/
    const pinRef = useRef()

    /*---------------------------------------HOOKS-----------------------------------------*/
    const dispatch = useDispatch()
    const { message: idleTimerEvt, start } = useIdleTimerContext()

    /*---------------------------------------FUNCTIONS-----------------------------------------*/
    const handleLogout = useCallback(() => {
        dispatch(setAppOnIdle(false))
        dispatch(logout())
    }, [dispatch])

    const validatePin = useCallback(async () => {
        try {
            await axios.post('/session/validate-pin', {
                pin: pinRef.current.value.join('')
            }, { appSpinner: true })
            start()
            dispatch(setAppOnIdle(false))
            idleTimerEvt()
        } catch (error) {
            pinRef.current.setStatus('error')
            setWrongPin('PIN incorrecto, Intenta nuevamente.')
            console.log('VALIDATE_PIN_ERROR', error)
        }
    }, [dispatch, idleTimerEvt, start])

    const handleChangePin = useCallback((value) => {
        if (value.length === pinLength)
            validatePin()
    }, [validatePin])

    return isIdle ?
        <Grid className='idle_app_mask'>
            <Grid className='idle_app_mask__content' columns="0.8fr 1fr" >
                <Grid className='magiei_security_container'>
                    <Img
                        src='https://magiei2.s3.us-east-2.amazonaws.com/public/img/png/MD-magiei-PIN.png'
                        width='432px'
                        height='432px'
                    />
                </Grid>
                <Grid gap='2rem' padding='0 2rem' itemsX='center' contentY='space-evenly'>
                    <Grid gap='1.14rem' itemsX='center'>
                        <Icon icon='icono-seguridad' filter='blue--7' size='4' />
                        <Text bold className='security_title_label' size='4' align='center'>Pantalla protegida por inactividad.</Text>
                    </Grid>
                    <Grid gap='1.8rem' maxWidth="15.74rem">
                        <Text bold size='8' align='center'>Escribe tu PIN para desbloquear</Text>
                        <Grid gap="0.714rem">
                            <CharacterField type="password" ref={pinRef} length={pinLength} onChange={handleChangePin} pattern={regexDictionary.onlyIntegerNumbers} />
                            {wrongPin !== '' && <Text color="error" align="center" size="2">{wrongPin}</Text>}
                        </Grid>
                    </Grid>
                    <Link underline align='center' onClick={handleLogout}>
                        Cerrar sesión e Ingresar con usuario y contraseña
                    </Link>
                </Grid>
            </Grid>
        </Grid>
        :
        null
}

export default IdleAppMask