import React, { useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, Text, TextField, Password } from 'components'
import { login } from 'reduxStore/reducers/auth/authSlice'

import './login.scss'

const Login = (props) => {
    const { handleCurrentScreen } = props
    const [formData, setFormData] = useState({ email: '', password: '' })
    const [showError, setShowError] = useState(false)
    const [error, setError] = useState('')

    const emailRef = useRef()
    const passwordRef = useRef()

    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChange = useCallback((value, name) => {
        setFormData(formData => ({ ...formData, [name]: value }))
    }, [])

    const handleSumbit = async e => {
        e.preventDefault()

        if (formData.email === '' || formData.password === '') {
            setError('Hay campos vacios')
            setShowError(true)
        } else {
            dispatch(login(formData)).then(res => {
                setShowError(true)
                setError('El correo electrónico o la contraseña son incorrectos')
                emailRef.current.validate()
                emailRef.current.setStatus('error')
                passwordRef.current.validate()
                passwordRef.current.setStatus('error')
                
            })
        }
    }

    return (
        <Grid className="login" itemsX="center" gap="1.3em" w100 padding="0rem 1.2rem 1.5rem 1.2rem   ">
            <Grid className="login__content" w100 gap="2.28em">
                <Grid className="login__content__form" gap="1.42rem">
                    <Text bold align="center" color="third" size="4">Inicia sesión</Text>
                    <form onSubmit={(e) => {handleSumbit(e)}} autoComplete='off'>
                        <Grid gap="1.71rem">
                            <TextField label="Correo electrónico"
                                type="email"
                                value={formData.email}
                                onChange={v => handleChange(v, 'email')}
                                ref={emailRef}
                            />
                            <Password label="Contraseña"
                                validate = {false}
                                value={formData.password}
                                onChange={v => handleChange(v, 'password')}
                                passwordRef={passwordRef}
                            />
                            {showError &&
                                <Text size="2" color="error">{error}</Text>
                            }
                            <Button type="submit"  selfCenter>Entrar</Button>
                        </Grid>
                    </form>
                    <Grid gap="1.2em" margin="0.86rem 0rem 0rem 0rem">
                        <Text medium align="center">
                            No tengo cuenta <span onClick={() => {handleCurrentScreen(1)}} className="want_open_account">Quiero abrir una.</span>
                        </Text>
                        <Text  className="password_forgotten" onClick={() => {handleCurrentScreen(2)}} underline medium align="center">
                            Olvidé mi contraseña
                        </Text>
                    </Grid>
                </Grid>
                
                <Grid gap="0.85rem" className="login__content__footer">
                    <Grid style={{justifySelf: 'center'}} columns="auto 1fr" gap="0.714em" itemsY="center">
                        <Text size="2" color="eighth" medium>Optimizado para Google Chrome.</Text>
                        <img alt="chrome" width="28px" src="https://magiei-resources.s3.us-east-2.amazonaws.com/Icons/IconoChrome.svg" />
                    </Grid>
                    <Text align="center" size="2" color="eighth" medium>InteliGene® y MaGiEi® son marcas registradas. Todos los derechos reservados.</Text>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Login