import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IdleTimerProvider } from "react-idle-timer"

import { setAppOnIdle } from "reduxStore/reducers/app/appSlice"

const minToMs = (min) => isNaN(min) ? 0 : (min * 6e4)

const IdleAppProvider = ({ children }) => {

    /*---------------------------------------STATE-----------------------------------------*/
    const { modePinActivated, blockingTime } = useSelector(store => store.auth.user.settingsUser)

    /*---------------------------------------HOOKS-----------------------------------------*/
    const dispatch = useDispatch()

    /*---------------------------------------FUNCTIONS-----------------------------------------*/
    const handleIdle = useCallback(() => {
        dispatch(setAppOnIdle(true))
    }, [dispatch])

    const handleMessage = useCallback(() => {
        window.location.href = '/'
    }, [])

    /*---------------------------------------EFFECTS-----------------------------------------*/
    useEffect(() => {
        if (modePinActivated) {
            const lastAppInteraction = localStorage.lastAppInteraction
            const currentDate = new Date()
            const lastInteractionDate = lastAppInteraction && new Date(lastAppInteraction)
            let isAppOnIdle = JSON.parse(localStorage.getItem('isIdle')) || false

            if (lastAppInteraction && currentDate > lastInteractionDate)
                isAppOnIdle = true

            if (isAppOnIdle)
                dispatch(setAppOnIdle(true))
        }
    }, [dispatch, modePinActivated])

    return (
        <IdleTimerProvider
            crossTab
            startManually
            stopOnIdle
            syncTimers={200}
            onMessage={handleMessage}
            timeout={minToMs(blockingTime || 1)}
            throttle={500}
            onIdle={handleIdle}
        >
            {children}
        </IdleTimerProvider>
    )
}

export default IdleAppProvider
