import React, { createRef, useCallback, useRef, useState, useMemo, useEffect, } from 'react'
import axios from 'axios'
import { useDispatch, useSelector  } from 'react-redux';
import { setCompany } from 'reduxStore/reducers/auth/authSlice'
import { ComboBox, TextField, Text, MessageBox, Button } from 'components'

import { formKeys,  BusinessProfileFormObj  } from '../form';

export const useBussinessProfileForm = ({options}) => {
    //--------------------------------REDUX -----------------------------------------------------//
    const dispatch = useDispatch();
    const { company } =  useSelector(store => store.auth)

    //--------------------------------STATE-----------------------------------------------------//
     const [businessProfile, setBusinessProfile] = useState({});
     const [businessProfileErrors, setBusinessProfileErrors] = useState(formKeys.reduce((a, v) => ({ ...a, [v]: false}), {}))
     const [inputChanged, setInputChanged] = useState(false)

    //-------------------------------HOOKS-------------------------------------------------------//
        useEffect(( ) => {
            if( company )
                setBusinessProfile(company)
        }, [ company ])

    //--------------------------REFERENCES------------------------------------------------------//
     const references = useRef(formKeys.map(()=> createRef()))
     const messageBoxRef = useRef()

    //-------CALL BACKS-------------------------------------------------//
        const handleChange = useCallback((value, key) =>{
            setBusinessProfile((profile) => ({...profile, [key]: value}));
            setInputChanged(true)
            setBusinessProfileErrors(errors => ({ ...errors, [key]: false}))
        }, [ setInputChanged ])

        const handleEnterKeyPressed = useCallback((event, index) => {
            const nextInputIndex = index + 1
            if(event.key === "Enter" && nextInputIndex < formKeys.length)
                if(nextInputIndex !== 2) //IT'S NOT WORKING ON COMBOBOX
                    references.current[nextInputIndex].current.focus()
        }, [])

        const handleSubmit = () => {
            const { companyIconUrl, companyLogoUrl, ...rest} = businessProfile
            let error = false;
            
            formKeys.forEach((key, index) => {
                if(!references.current[index].current.validate() || !rest[key] ){
                    references.current[index].current.setStatus('error')
                    setBusinessProfileErrors(errors => ({...errors, [key]: true}))
                    error = true;
                }
            })

            if(!error) { 
                axios
                .put('/company/account', rest, { appProcessing: true, saveProcessing: true })
                .then((response) => {
                    if( response.error ) {

                    }  else {
                        setInputChanged(false);
                        dispatch(setCompany( response.data ));
                    }
                })
                .catch(err => {
                    messageBoxRef.current.show({
                        content: <Text medium align="center">No cuentas con los permisos necesarios para realizar esta accion.</Text>,
                        buttons: <Button onClick={() => { messageBoxRef.current.hide() }}>Entendido</Button>
                    })
                })
            }
        }

        const createComponent = useCallback((component, props) => {
            if(component === 'textfield') return <TextField {...props}/>
            return <ComboBox {...props} options={ options }/>
        }, [ options ])


    //------------LAYOUT - FUNCTIONS------------------------------------------------------------//
    const componentsDOM = useMemo(( ) => {
        return formKeys.map((key, index) =>
          <section>
              <MessageBox ref={messageBoxRef} />
            { 
                createComponent(
                    BusinessProfileFormObj[key].input,
                    {   
                        label: BusinessProfileFormObj[key].label,
                        value: businessProfile[key],
                        ref: references.current[index],
                        onKeyDown: (event) => handleEnterKeyPressed(event, index),
                        onChange: (event) => handleChange(event, key),
                        ...BusinessProfileFormObj[key].props,
                        
                    }
                )
            }
            { businessProfileErrors[key] && <Text style={{marginTop: '0.31rem'}} regular size="2" className='status--error'>{ BusinessProfileFormObj[key].message(businessProfile[key]) }</Text>}
         </section>
        )   
    }, [businessProfile, businessProfileErrors, createComponent, handleChange, handleEnterKeyPressed])
    
    return { componentsDOM, handleSubmit, inputChanged }
}

